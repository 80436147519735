import React from "react";
import { useState } from "react";
import './styles/Slider.scss'

const Slider = ({slides}) => {
  const [index, setIndex] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const getPrevious = () => {
    const isFirst = (index === 0);
    setIndex(isFirst ? slides.length - 1 : index - 1);
  };
  const getNext = () => {
    const isLast = (index === slides.length - 1); 
    setIndex(isLast ? 0 : index + 1);
  };

  const title = slides[index].title
  const text = slides[index].caption

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

    return (
        <div className="slide" onMouseMove={handleMouseMove}>
          <div className="slideChange" onClick={getNext}>
            <div className="nextSlide" ></div>
            <div className="dummySlide"></div>
          </div>
          <div className="slideText" style={{backgroundImage: `url(${slides[index].url})`}}>
            <h2>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br/>') }}></p>
            <div
              className="mouseGlow"
              style={{
                left: `${mousePosition.x}px`,
                top: `${mousePosition.y}px`,
              }}
            ></div>
          </div>
        </div>
    );
  };

export default Slider;